import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SmartCategorizationModal from "./SmartCategorization_Modal/SmartCategorization_Modal";
import FreeImportsModal from "./FreeImports_Modal/FreeImports_Modal";
import RiskFreeModal from "./RiskFree_Modal/RiskFree_Modal";
import SecureModal from "./Secure_Modal/Secure_Modal";
import WaitlistModal from "./Waitlist_Modal/Waitlist_Modal";
import HeroImage from "../Images/money-6163908_640.png";

import {
  FaChartLine,
  FaDownload,
  FaLaptop,
  FaLock,
  FaInstagram,
} from "react-icons/fa";

const Landing = () => {
  let navigate = useNavigate();
  const [showSmartCategorizationModal, setShowSmartCategorizationModal] =
    useState(false);
  const [showFreeImports, setShowFreeImports] = useState(false);
  const [showRiskFree, setShowRiskFree] = useState(false);
  const [showSecure, setShowSecure] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);

  return (
    <div className='landing-page' style={{ backgroundColor: "#f2f2f2" }}>
      <SmartCategorizationModal
        i_show={showSmartCategorizationModal}
        fi_handleCancel={() => setShowSmartCategorizationModal(false)}
      />
      <FreeImportsModal
        i_show={showFreeImports}
        fi_handleCancel={() => setShowFreeImports(false)}
      />
      <RiskFreeModal
        i_show={showRiskFree}
        fi_handleCancel={() => setShowRiskFree(false)}
      />
      <SecureModal
        i_show={showSecure}
        fi_handleCancel={() => setShowSecure(false)}
      />
      <WaitlistModal
        i_show={showWaitlist}
        fi_handleCancel={() => setShowWaitlist(false)}
      />

      {/* Navigation */}
      <nav
        className='navbar navbar-expand-lg navbar-dark'
        style={{ backgroundColor: "#2b6777" }}>
        <div className='container'>
          <a className='navbar-brand' href='#'>
            Easy Breezy Budget
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <a className='nav-link' href='#features'>
                  Features
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#how-it-works'>
                  How It Works
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#pricing'>
                  Pricing
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#faq'>
                  FAQ
                </a>
              </li>
              {/* <li className='nav-item'>
                <a className='nav-link' href='/login'>
                  Sign In
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className='hero py-5' style={{ backgroundColor: "#c8d8e4" }}>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6'>
              <h1 className='display-4 fw-bold' style={{ color: "#2b6777" }}>
                Budgeting doesn't have to be complicated.
              </h1>
              <p className='lead' style={{ color: "#2b6777" }}>
                Take control of your finances with Easy Breezy Budget! Our
                user-friendly personal finance platform makes it simple to
                manage your money.
              </p>
              <button
                onClick={() => setShowWaitlist(true)}
                className='btn btn-primary btn-lg'
                style={{ backgroundColor: "#52ab98", borderColor: "#52ab98" }}>
                Join Waitlist
              </button>
            </div>
            <div className='col-lg-6'>
              <img
                src={HeroImage}
                alt='Budget illustration'
                className='img-fluid rounded shadow'
              />
            </div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section id='features' className='py-5'>
        <div className='container'>
          <h2 className='text-center mb-5' style={{ color: "#2b6777" }}>
            Why Choose Easy Breezy Budget?
          </h2>
          <div className='row g-4'>
            <div className='col-md-6 col-lg-3'>
              <div className='card h-100 border-0 shadow-sm'>
                <div className='card-body text-center'>
                  <FaChartLine
                    className='display-1 text-primary mb-3'
                    style={{ color: "#52ab98" }}
                  />
                  <h3 className='card-title' style={{ color: "#2b6777" }}>
                    Smart Categorization
                  </h3>
                  <p className='card-text'>
                    Create custom categories that fit your unique spending
                    habits.
                  </p>
                  <button
                    onClick={() => setShowSmartCategorizationModal(true)}
                    className='btn btn-outline-primary mt-3'
                    style={{ color: "#52ab98", borderColor: "#52ab98" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div className='card h-100 border-0 shadow-sm'>
                <div className='card-body text-center'>
                  <FaDownload
                    className='display-1 text-primary mb-3'
                    style={{ color: "#52ab98" }}
                  />
                  <h3 className='card-title' style={{ color: "#2b6777" }}>
                    Easy Onboarding
                  </h3>
                  <p className='card-text'>
                    Let us move your data over so you don't have to start from
                    scratch.
                  </p>
                  <button
                    onClick={() => setShowFreeImports(true)}
                    className='btn btn-outline-primary mt-3'
                    style={{ color: "#52ab98", borderColor: "#52ab98" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div className='card h-100 border-0 shadow-sm'>
                <div className='card-body text-center'>
                  <FaLaptop
                    className='display-1 text-primary mb-3'
                    style={{ color: "#52ab98" }}
                  />
                  <h3 className='card-title' style={{ color: "#2b6777" }}>
                    Free Trial
                  </h3>
                  <p className='card-text'>
                    Ensure this is the perfect solution for your needs before
                    yoyu commit.
                  </p>
                  <button
                    onClick={() => setShowRiskFree(true)}
                    className='btn btn-outline-primary mt-3'
                    style={{ color: "#52ab98", borderColor: "#52ab98" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div className='card h-100 border-0 shadow-sm'>
                <div className='card-body text-center'>
                  <FaLock
                    className='display-1 text-primary mb-3'
                    style={{ color: "#52ab98" }}
                  />
                  <h3 className='card-title' style={{ color: "#2b6777" }}>
                    Secure & Private
                  </h3>
                  <p className='card-text'>
                    Your financial data is encrypted and never shared with third
                    parties.
                  </p>
                  <button
                    onClick={() => setShowSecure(true)}
                    className='btn btn-outline-primary mt-3'
                    style={{ color: "#52ab98", borderColor: "#52ab98" }}>
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section
        id='how-it-works'
        className='py-5'
        style={{ backgroundColor: "#ffffff" }}>
        <div className='container'>
          <h2 className='text-center mb-5' style={{ color: "#2b6777" }}>
            How It Works
          </h2>
          <div className='row g-4'>
            <div className='col-md-4'>
              <div className='text-center'>
                <div
                  className='rounded-circle bg-primary d-inline-flex align-items-center justify-content-center mb-3'
                  style={{
                    width: "80px",
                    height: "80px",
                    backgroundColor: "#52ab98",
                  }}>
                  <h3 className='text-white m-0'>1</h3>
                </div>
                <h4 style={{ color: "#2b6777" }}>Sign Up</h4>
                <p>Create your account and set up your budget categories.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='text-center'>
                <div
                  className='rounded-circle bg-primary d-inline-flex align-items-center justify-content-center mb-3'
                  style={{
                    width: "80px",
                    height: "80px",
                    backgroundColor: "#52ab98",
                  }}>
                  <h3 className='text-white m-0'>2</h3>
                </div>
                <h4 style={{ color: "#2b6777" }}>Track Expenses</h4>
                <p>Log your expenses and income as they occur.</p>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='text-center'>
                <div
                  className='rounded-circle bg-primary d-inline-flex align-items-center justify-content-center mb-3'
                  style={{
                    width: "80px",
                    height: "80px",
                    backgroundColor: "#52ab98",
                  }}>
                  <h3 className='text-white m-0'>3</h3>
                </div>
                <h4 style={{ color: "#2b6777" }}>Analyze & Adjust</h4>
                <p>
                  Review your spending patterns and adjust your budget as
                  needed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonials Section */}
      <section className='py-5' style={{ backgroundColor: "#c8d8e4" }}>
        <div className='container'>
          <h2 className='text-center ' style={{ color: "#2b6777" }}>
            What Our Future Users Might Say
          </h2>
          <h6 className='text-center mb-5' style={{ color: "#2b6777" }}>
            (We're still developing / beta testing)
          </h6>
          <div className='row'>
            <div className='col-md-4 mb-4'>
              <div className='card border-0 shadow-sm h-100'>
                <div className='card-body'>
                  <p className='card-text'>
                    "Easy Breezy Budget has completely transformed how I manage
                    my finances. It's intuitive and actually makes budgeting
                    enjoyable!"
                  </p>
                  <p
                    className='font-weight-bold mb-0'
                    style={{ color: "#2b6777" }}>
                    - Sarah J.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 mb-4'>
              <div className='card border-0 shadow-sm h-100'>
                <div className='card-body'>
                  <p className='card-text'>
                    "I've tried many budgeting apps, but this one stands out.
                    The customizable categories and real-time tracking are
                    game-changers."
                  </p>
                  <p
                    className='font-weight-bold mb-0'
                    style={{ color: "#2b6777" }}>
                    - Mike T.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4 mb-4'>
              <div className='card border-0 shadow-sm h-100'>
                <div className='card-body'>
                  <p className='card-text'>
                    "As someone who always struggled with budgeting, Easy Breezy
                    Budget has made it simple and stress-free. Highly
                    recommended!"
                  </p>
                  <p
                    className='font-weight-bold mb-0'
                    style={{ color: "#2b6777" }}>
                    - Emily R.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id='pricing' className='py-5'>
        <div className='container'>
          <h2 className='text-center mb-5' style={{ color: "#2b6777" }}>
            Simple, Transparent Pricing
          </h2>
          <div className='row justify-content-center'>
            <div className='col-md-4'>
              <div className='card border-0 shadow-sm'>
                <div className='card-body text-center'>
                  <h3 className='card-title' style={{ color: "#2b6777" }}>
                    Monthly Plan
                  </h3>
                  <h4 className='card-price'>
                    <span style={{ textDecoration: "line-through" }}>$8</span>
                    <span
                      style={{ textDecoration: "line-through" }}
                      className='period'>
                      /month
                    </span>
                  </h4>
                  <h4 style={{ color: "red", marginTop: "10px" }}>FREE</h4>
                  <ul className='list-unstyled mt-3 mb-4'>
                    <li>All features included</li>
                    <li>Unlimited categories</li>
                    <li>Real-time sync across devices</li>
                    <li>Priority customer support</li>
                  </ul>
                  <button
                    onClick={() => setShowWaitlist(true)}
                    className='btn btn-primary btn-lg'
                    style={{
                      backgroundColor: "#52ab98",
                      borderColor: "#52ab98",
                    }}>
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id='faq' className='py-5' style={{ backgroundColor: "#f2f2f2" }}>
        <div className='container'>
          <h2 className='text-center mb-5' style={{ color: "#2b6777" }}>
            Frequently Asked Questions
          </h2>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <div className='accordion' id='accordionFAQ'>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                      style={{ color: "#2b6777" }}>
                      How much does Easy Breezy Budget cost?
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionFAQ'>
                    <div className='accordion-body'>
                      Easy Breezy Budget costs $8 per month. This gives you
                      access to all features, unlimited categories, and priority
                      customer support.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                      style={{ color: "#2b6777" }}>
                      Is there a free trial available?
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionFAQ'>
                    <div className='accordion-body'>
                      Yes! We offer a 30-day free trial so you can test out our
                      service and see if it's the right fit for you. No credit
                      card is required to start your trial.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                      style={{ color: "#2b6777" }}>
                      Can I import my bank transactions?
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionFAQ'>
                    <div className='accordion-body'>
                      Yes, Easy Breezy Budget supports importing bank
                      transactions. You can upload transaction files to keep
                      your budget up-to-date.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer
        className='py-4'
        style={{ backgroundColor: "#2b6777", color: "#ffffff" }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h5>Easy Breezy Budget</h5>
              <p>Simplifying personal finance management for everyone.</p>
            </div>
            <div className='col-md-3'>
              <h5>Legal</h5>
              <ul className='list-unstyled'>
                <li>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/privacypolicy/privacypolicy");
                    }}
                    style={{ color: "#ffffff", textDecoration: "none" }}>
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href='#'
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/terms/terms");
                    }}
                    style={{ color: "#ffffff", textDecoration: "none" }}>
                    Terms and Conditions
                  </a>
                </li>
              </ul>
            </div>
            <div className='col-md-3'>
              <h5>Contact</h5>
              <ul className='list-unstyled'>
                <li>Email: support_team@easybreezybudget.com</li>
                <li>
                  <a
                    href='https://www.instagram.com/easybreezybudget4u/'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ color: "#ffffff", textDecoration: "none" }}>
                    <FaInstagram size={24} style={{ marginRight: "5px" }} />
                    Follow us on Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr style={{ backgroundColor: "#ffffff" }} />
          <div className='text-center'>
            <p>&copy; 1024 Enterprises. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
