import "./BankImport.css";

import { useState, useEffect, useRef } from "react";
import { getFromAPI, putToAPI, postToAPI } from "../../Components/lib/api";
import { useNavigate } from "react-router-dom";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { dateForAPI } from "../../Components/lib/utils.jsx";
import { qfxArray } from "../../Components/lib/QFX.jsx";
import BankTransDraggable from "./BankTransDraggable.jsx";
import DBTransDroppable from "./DBTransDroppable";
import EnvelopeDroppable from "./EnvelopeDroppable";
import AddTransaction from "../../Components/AddTransaction/AddTransaction.jsx";
import TransReconciliation from "./TransReconciliation.jsx";

const BankImport = () => {
  const fileInputRef = useRef(null);
  let theToken = sessionStorage.getItem("token");
  let navigate = useNavigate();
  const strURL_Envelopes = `${process.env.REACT_APP_API_URL}/api/envelope`;
  const strURL_Transactions = `${process.env.REACT_APP_API_URL}/api/transaction`;
  const strURL_UpdateFITID = `${process.env.REACT_APP_API_URL}/api/updateFITIDs`;
  const strURL_GetHiddenFITID = `${process.env.REACT_APP_API_URL}/api/getIgnoredFITIDs`;
  const strURL_SaveHiddenFITID = `${process.env.REACT_APP_API_URL}/api/saveIgnoredFITIDs`;
  const strURL_TransEnvelopes = `${process.env.REACT_APP_API_URL}/api/transactionEnvelopes`;

  const [bankTransactions, setBankTransactions] = useState([]); // These are loaded from the qfx file.
  const [dbTransactions, setDbTransactions] = useState([]); // These are loaded from the database.
  const [envelopes, setEnvelopes] = useState([]); // List of all Envelopes in the users account.
  const [newTrans, setNewTrans] = useState({}); // (singular) Transaction being created. Sent to component.
  const [listOfNewTrans, setListOfNewTrans] = useState([]); // (singular or plural) List of new transactions that have been created on this screen and are waiting to be created on the server (or canceled)
  const [activeId, setActiveId] = useState(null); // Used for drag n drop.
  const [currentBankTransIndex, setCurrentBankTransIndex] = useState(null); // The index of the bank transition the user has dropped onto a db transaction.
  const [currentDBTransIndex, setCurrentDBTransIndex] = useState(null); // The index of the db transaction the user has dropped a bank transaction onto.
  const [currentTransEnvelopes, setCurrentTransEnvelopes] = useState([]); // List of envelopes that make up the given transaction. Transactions can be assigned to multiple envelopes.
  const [showDBTrans, setShowDBTrans] = useState(false); // Show the db transactions (that haven't previously been matched) on the main screen.
  const [showAddTrans, setShowAddTrans] = useState(false); // Show the Add Transaction popup.
  const [showTransReconcilation, setShowTransReconcilation] = useState(false); // Show the Transaction Reconciliation popup.
  const [hiddenFITIDs, setHiddenFITIDs] = useState([]); // New state for hidden FITIDs
  const [showInstructions, setShowInstructions] = useState(true);

  // ** FUNCTION (API): Get all envelopes from database.
  const getEnvelopes = async () => {
    const envelopesFromServer = await getFromAPI(theToken, strURL_Envelopes);
    const newArray = envelopesFromServer.map((obj) => ({
      ...obj,
      hasNew: false,
    }));
    setEnvelopes(newArray);
  };

  // ** FUNCTION (API): Get all transactions from server.
  const getTransactions = async () => {
    const transactionsFromServer = await getFromAPI(
      theToken,
      strURL_Transactions
    );

    // Get rid of Envelope Transfers & funding from Unallocated
    const tempDBTransactions = transactionsFromServer.filter(
      (trans) =>
        trans.type != process.env.REACT_APP_Type_EnvTransfer &&
        trans.type != process.env.REACT_APP_Type_UnallocatedFill
    );

    // Add additional properties to each transaction object.
    tempDBTransactions.forEach((item) => {
      item.newFITID = null;
      item.editedValues = null;
    });

    setDbTransactions(tempDBTransactions);
  };

  // ** FUNCTION (API): Get list of all FITIDs to ignore from the server.
  const getIgnoredFITIDs = async () => {
    const FITIDsFromServer = await getFromAPI(theToken, strURL_GetHiddenFITID);
    console.log(FITIDsFromServer);
    setHiddenFITIDs(FITIDsFromServer.map((item) => item.FITID));
  };

  // ** FUNCTION (API): Get a specific transaction's envelope(s) from server.
  const getTransactionEnvelopes = async (transactionID) => {
    const transEnvelopesFromServer = await getFromAPI(
      theToken,
      `${strURL_TransEnvelopes}/${transactionID}`
    );
    setCurrentTransEnvelopes(transEnvelopesFromServer);
  };

  // ** Load the qfx file.
  const handleLoadFile = async (event) => {
    event.preventDefault();

    if (document.getElementById("filePicker").files.length === 0) {
      alert("Please pick a file.");
    } else {
      const file = document.getElementById("filePicker").files[0];
      let tempBankTrans;
      try {
        tempBankTrans = await qfxArray(file); //Convert the QFX file into an array of transaction objects.
      } catch (error) {
        console.error("An error occurred: ", error);
      }

      // Extract FITID values from both arrays into separate arrays
      let fitidArray1 = tempBankTrans.map((item) => item.FITID);
      let fitidArray2 = dbTransactions.map((item) => item.FITID);

      // Find matching FITID values
      let matchingFITIDs = fitidArray1.filter((fitid) =>
        fitidArray2.includes(fitid)
      );

      // Use filter to remove items with matching FITIDs from both arrays
      tempBankTrans = tempBankTrans.filter(
        (item) => !matchingFITIDs.includes(item.FITID)
      );

      // Remove items from tempBankTrans that have an FITID found in hiddenFITIDs
      tempBankTrans = tempBankTrans.filter(
        (trans) => !hiddenFITIDs.includes(trans.FITID)
      );

      setBankTransactions(tempBankTrans);

      const tempDBTrans = dbTransactions.filter(
        (trans) =>
          trans.FITID == null &&
          trans.status !== process.env.REACT_APP_Status_Transfer &&
          trans.status !== process.env.REACT_APP_Status_Cleared &&
          trans.status !== process.env.REACT_APP_Status_Reconciled
      );

      setDbTransactions(tempDBTrans);

      setShowDBTrans(true);
    }
  };

  // ** Start dragging of the bank transactions on the screen.
  function handleDragStart(event) {
    setActiveId(event.active.id); // This has to be set so the drag animation will show.
  }

  // ** Releasing the dragging of the bank transaction on the screen. Usually dropping onto a DB Transaction or an Envelope.
  const handleDragEnd = async (event) => {
    const { active, over } = event;
    let bankIndex;
    let dbIndex;
    let envIndex;

    // First, check if 'over' exists (i.e., if the item was dropped on a valid target)
    if (!over) {
      setActiveId(null);
      return;
    }

    if (active.id !== over.id) {
      // If dropped onto a dbTransaction...
      if (over.id.toString().startsWith("DBTran:")) {
        // Verify amounts match...
        bankIndex = bankTransactions.findIndex(
          (item) => item.FITID.toString() === active.id
        );
        dbIndex = dbTransactions.findIndex(
          (item) => "DBTran:" + item.transactionID === over.id
        );

        // Check if both indices are valid
        if (bankIndex === -1 || dbIndex === -1) {
          console.error("Invalid drag and drop operation");
          setActiveId(null);
          return;
        }

        // If they do, record the FITID for the transaction.
        if (
          bankTransactions[bankIndex].Amount == dbTransactions[dbIndex].amount
        ) {
          // Update FITID in dbTransactions
          let tempArray = [...dbTransactions];
          tempArray[dbIndex].newFITID = bankTransactions[bankIndex].FITID;
          setDbTransactions(tempArray);

          // Mark the item as Matched in bankTransactions
          tempArray = [...bankTransactions];
          tempArray[bankIndex].Matched = true;
          setBankTransactions(tempArray);
        } else {
          // If they don't match, display the reconciliation popup for the user.
          setCurrentBankTransIndex(bankIndex);
          setCurrentDBTransIndex(dbIndex);

          // Setup the DB Transaction envelope to be sent to TransReconciliation.
          await getTransactionEnvelopes(dbTransactions[dbIndex].transactionID);

          setShowTransReconcilation(true);
        }

        // If dropped onto an Envelope (create a new transaction in the DB)
      } else if (over.id.toString().startsWith("Env:")) {
        // Setup the transaction to be sent to popup.
        bankIndex = bankTransactions.findIndex(
          (item) => item.FITID.toString() === active.id
        );
        envIndex = envelopes.findIndex((item) => "Env:" + item.id === over.id);

        // Check if both indices are valid
        if (bankIndex === -1 || envIndex === -1) {
          console.error("Invalid drag and drop operation");
          setActiveId(null);
          return;
        }

        let tempTrans = [];
        tempTrans[0] = {
          transactionID: 0,
          transaction_Total: bankTransactions[bankIndex].Amount,
          note: bankTransactions[bankIndex].Memo,
          payee: bankTransactions[bankIndex].Name,
          date: formatBankDate(bankTransactions[bankIndex].DatePosted),
          FITID: bankTransactions[bankIndex].FITID,
        };
        // Setup the envelope listing to be sent to popup
        const tempEnv = [
          {
            envelopeID: envelopes[envIndex].id,
            amount: bankTransactions[bankIndex].Amount,
          },
        ];
        tempTrans.push(tempEnv);
        setNewTrans(tempTrans);

        // Show the popup (with the pre-populated fields (from the bank transaction))
        setShowAddTrans(true);
      }
    }

    setActiveId(null);
  };

  // ** Clicking the UNMATCH button on a previously matched transaction (in the DB Transactions column).
  const handleDBUnmatchClick = (FITID) => {
    // Update bankTransitions
    let index = bankTransactions.findIndex((trans) => trans.FITID == FITID);
    if (index > -1) {
      const tempBankArray = [...bankTransactions];
      tempBankArray[index].Matched = false;
      setBankTransactions(tempBankArray);
    }

    // Update dbTransactions
    index = dbTransactions.findIndex((trans) => trans.newFITID == FITID);
    if (index >= -1) {
      const tempDBArray = [...dbTransactions];
      tempDBArray[index].newFITID = null;
      tempDBArray[index].editedValues = null;
      setDbTransactions(tempDBArray);
    }
  };

  // ** Clicking the UNMATCH button on a previously 'added to' envelope (in the Category column).
  const handleEnvUnmatchClick = (EnvelopeID) => {
    // Create list of which FITIDs need to be Unmatched.
    let listFITIDs = listOfNewTrans.filter(
      (item) => item.destEnvelopes[0].envelope == EnvelopeID
    );

    // Remove the pending transactions from the list of transations to be created.
    let tempNewTrans = listOfNewTrans;
    tempNewTrans = tempNewTrans.filter(
      (item) => item.destEnvelopes[0].envelope != EnvelopeID
    );
    setListOfNewTrans(tempNewTrans);

    // Update list of bankTransactions (to show the previously matched/created transactions)
    let tempBankArray = [...bankTransactions];

    listFITIDs.forEach((currentFITID) => {
      const bankIndex = tempBankArray.findIndex(
        (bankTrans) => bankTrans.FITID == currentFITID.FITID
      );
      tempBankArray[bankIndex].Matched = false;
    });
    setBankTransactions(tempBankArray);

    // Update list of new transactions to be created (Get rid of the previously created transactions for the given envelope)
    let tempEnvArray = [...envelopes];
    const tempEnvIndex = tempEnvArray.findIndex(
      (currentEnv) => currentEnv.id == EnvelopeID
    );
    tempEnvArray[tempEnvIndex].hasNew = false;
  };

  // ** Save the newly created transaction from the popup (dragging a bank transaction onto an envelope) to the appropriate list of transactions to be saved when the page-Save button is pressed.
  const handleSaveNewTrans = (transaction) => {
    setListOfNewTrans([...listOfNewTrans, transaction]);

    // Mark the item as Matched in bankTransactions
    const bankIndex = bankTransactions.findIndex(
      (item) => item.FITID == transaction.FITID
    );
    let tempArray = [...bankTransactions];
    tempArray[bankIndex].Matched = true;
    setBankTransactions(tempArray);

    // Mark the envelope as having a new transaction.
    const envelopeIndex = transaction.destEnvelopes[0].envelope;
    const arrayIndex = envelopes.findIndex((item) => item.id == envelopeIndex);
    tempArray = envelopes;
    tempArray[arrayIndex].hasNew = true;
    setEnvelopes(tempArray);

    setShowAddTrans(false);
  };

  // ** Save reconciled transaction to the appropriate list of transactions to be saved when the page-Save button is pressed.
  const handleSaveTransReconciliation = (transaction) => {
    // Tweak a few items for the API
    //    'envelope' instead of 'EnvelopeID'.
    let tempEnvelopes = transaction.EnvelopeBreakdown;
    tempEnvelopes = tempEnvelopes.map((obj) => ({
      ...obj,
      envelope: obj.envelopeID,
    }));
    transaction.EnvelopeBreakdown = tempEnvelopes;
    //    'Date' has to be in correct format (YYYY-MM-YY)
    transaction.Date = dateForAPI(transaction.Date);

    // dbTransaction: Add the updated transaction details to the existing dbTransaction record.
    const dbIndex = dbTransactions.findIndex(
      (item) => item.transactionID == transaction.TransactionID
    );
    let tempArray = [...dbTransactions];
    tempArray[dbIndex].newFITID = transaction.FITID;
    tempArray[dbIndex].editedValues = transaction;
    setDbTransactions(tempArray);

    // bankTransaction: Mark the item as Matched in bankTransactions
    const bankIndex = bankTransactions.findIndex(
      (item) => item.FITID == transaction.FITID
    );
    tempArray = [...bankTransactions];
    tempArray[bankIndex].Matched = true;
    setBankTransactions(tempArray);

    setShowTransReconcilation(false);
  };

  // Save button at bottom of screen
  const handleSave = async () => {
    // Up to 4 different things going on here...
    // 1) Transactions that were a match and only had their FITIDs set.
    //    Send all of those at once to be processed by the backend.
    let tempArray = dbTransactions
      .filter((trans) => trans.newFITID !== null && trans.editedValues == null)
      .map((trans) => ({ id: trans.transactionID, FITID: trans.newFITID }));

    if (tempArray.length > 0) {
      console.table(tempArray);
      const formData = new URLSearchParams();
      formData.append("transFITIDs", JSON.stringify(tempArray));
      await putToAPI(theToken, strURL_UpdateFITID, formData);
    }

    // 2) Items that needed something changed (and FITID added) are sent one at a time.
    tempArray = dbTransactions.filter(
      (trans) => trans.newFITID !== null && trans.editedValues !== null
    );

    for (const transaction of tempArray) {
      const formData = new URLSearchParams();
      formData.append("payee", transaction.editedValues.Payee);
      formData.append("date", transaction.editedValues.Date);
      formData.append("checkNumber", transaction.editedValues.CheckNumber);
      formData.append("totalAmount", transaction.editedValues.Amount);
      formData.append("note", transaction.editedValues.Note);
      formData.append(
        "envelope",
        JSON.stringify(transaction.editedValues.EnvelopeBreakdown)
      );
      formData.append("type", transaction.type);
      formData.append("status", process.env.REACT_APP_Status_Cleared);
      formData.append("FITID", transaction.editedValues.FITID);

      await putToAPI(
        theToken,
        strURL_Transactions + "/" + transaction.transactionID,
        formData
      );
    }

    // 3) Bank transactions that were dropped on an Envelope (so new transaction being created).
    for (const transaction of listOfNewTrans) {
      const formData = new FormData();
      formData.append("payee", transaction.payee);
      formData.append("date", transaction.date);
      formData.append("checkNumber", transaction.checkNum);
      formData.append("totalAmount", transaction.totalAmount);
      formData.append("note", transaction.note);
      formData.append("envelope", JSON.stringify(transaction.destEnvelopes));
      formData.append("type", transaction.type);
      formData.append("status", process.env.REACT_APP_Status_Cleared);
      formData.append("FITID", transaction.FITID);

      await postToAPI(theToken, strURL_Transactions, formData);
    }

    // 4) If any bank transactions (FITIDs) have been marked as hidden, send those (all at once).
    console.log(hiddenFITIDs);
    const formData = new URLSearchParams();
    formData.append("FITIDs", JSON.stringify(hiddenFITIDs));
    await postToAPI(theToken, strURL_SaveHiddenFITID, formData);

    navigate("/main");
  };

  // Handle hiding a bank transaction if a user selects 'Hide' for the transaction.
  const handleHideTransaction = (FITID) => {
    console.log("handleHideTransaction called with FITID:", FITID);
    setBankTransactions((prevTransactions) => {
      const updatedTransactions = prevTransactions.filter(
        (transaction) => transaction.FITID !== FITID
      );
      return updatedTransactions;
    });
    setHiddenFITIDs((prevHiddenFITIDs) => {
      const updatedHiddenFITIDs = [...prevHiddenFITIDs, FITID];
      return updatedHiddenFITIDs;
    });
  };

  useEffect(() => {
    getTransactions();
    getEnvelopes();
    getIgnoredFITIDs();
  }, []);

  useEffect(() => {
    if (fileInputRef.current) {
      fileInputRef.current.focus();
    }
  }, []);

  const buttonClassName =
    bankTransactions.length === 0
      ? "btn btn-primary btn-block mx-2 disabled"
      : "btn btn-primary btn-block mx-2 ";

  return (
    <div className='container importPage'>
      {/* Add Transaction Modal */}
      {showAddTrans && (
        <AddTransaction
          i_show={showAddTrans}
          i_envelopes={envelopes}
          i_transaction={newTrans}
          fi_saveTransaction={handleSaveNewTrans}
          fi_handleCancel={() => {
            setShowAddTrans(false);
            setNewTrans({});
          }}
        />
      )}

      {/* Transaction Reconciliation Modal */}
      {showTransReconcilation && (
        <TransReconciliation
          i_show={showTransReconcilation}
          i_Envelopes={envelopes}
          i_BankTransaction={bankTransactions[currentBankTransIndex]}
          i_DBTransaction={dbTransactions[currentDBTransIndex]}
          i_DBTransEnvelopes={currentTransEnvelopes}
          fi_handleSave={handleSaveTransReconciliation}
          fi_handleCancel={() => {
            setShowTransReconcilation(false);
          }}
        />
      )}
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <DragOverlay>
          {activeId ? (
            <BankTransDraggable
              i_bankTran={bankTransactions.find(
                (element) => element.FITID === activeId
              )}
            />
          ) : null}
        </DragOverlay>

        <div className='importHeader'>
          {/* Instructions */}
          <div
            className={`instructions ${showInstructions ? "" : "hidden"}`}
            style={{
              backgroundColor: "#c8d8e4",
              padding: "15px",
              marginBottom: "20px",
              borderRadius: "5px",
              position: "relative",
            }}>
            <button
              onClick={() => setShowInstructions(false)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                background: "none",
                border: "none",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}>
              ×
            </button>
            <h5>How to use Bank Import:</h5>
            <ol>
              <li>
                Download your bank activity (QFX format) from your bank's
                website.
              </li>
              <li>
                Select the .QFX file using the 'Choose File' button, and then
                click the 'Load' button.
              </li>
              <li>
                Match bank transactions with existing Easy Breezy transactions
                by dragging and dropping. Drag by clicking on the ☰ symbol.
                <br />
                OR
                <br />
                Create a new transaction in Easy Breezy by dragging bank
                transactions to the appropriate category.
              </li>
              <li>
                Click 'Save' when you're done to update your Easy Breezy Budget.
              </li>
            </ol>
          </div>
          <form onSubmit={handleLoadFile}>
            <input
              type='file'
              id='filePicker'
              name='qfx file'
              ref={fileInputRef}
            />
            <button>Load</button>
          </form>
        </div>

        <div className='importBankTrans'>
          <h4 className='mt-4'>Bank Transactions</h4>
          <table className='table table-striped mt-4 small'>
            <tbody>
              {bankTransactions
                .filter((bankTran) => !bankTran.Matched)
                .map((bankTran) => (
                  <BankTransDraggable
                    key={bankTran.FITID}
                    i_bankTran={bankTran}
                    fi_onHide={handleHideTransaction}
                  />
                ))}
            </tbody>
          </table>
        </div>

        <div className='importDBTrans'>
          <h4 className='mt-4'>Easy Breezy Transactions</h4>
          <table className='table table-striped mt-4 small'>
            <tbody>
              {showDBTrans
                ? dbTransactions.map((DBTran) => (
                    <DBTransDroppable
                      key={DBTran.transactionID}
                      i_dbTran={DBTran}
                      fi_handleUnmatchClick={handleDBUnmatchClick}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>

        <div className='importEnvelopes'>
          <h4 className='mt-4'>Categories</h4>
          <table className='table table-striped mt-4 small'>
            <tbody>
              {envelopes.map((envelope) => (
                <EnvelopeDroppable
                  key={envelope.id}
                  i_Envelope={envelope}
                  fi_handleUnmatchClick={handleEnvUnmatchClick}
                />
              ))}
            </tbody>
          </table>
        </div>

        <div className='importFooter'>
          {/* Save Button */}
          <button className={buttonClassName} onClick={handleSave}>
            Save
          </button>
          {/* Cancel Button */}
          <button
            className='btn btn-primary btn-block'
            onClick={() => {
              navigate("/main");
            }}>
            Cancel
          </button>
        </div>
      </DndContext>
    </div>
  );
};
function formatBankDate(inputDate) {
  // Parse the input date string
  var parts = inputDate.split("/");
  var month = parts[0];
  var day = parts[1];
  var year = parts[2];

  // Create a Date object using the parsed values
  var parsedDate = new Date(year, month - 1, day);

  // Format the date into the desired format
  var formattedDate =
    parsedDate.getFullYear() +
    "-" +
    ("0" + (parsedDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + parsedDate.getDate()).slice(-2);

  return formattedDate;
}
export default BankImport;
