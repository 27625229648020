import { useState } from "react";
import Modal from "react-modal";
import currency from "currency.js";
import "./BankImport.css";
import "./TransReconciliation.css";
import "../../Components/EnvelopeBreakout/EnvelopeBreakout.jsx";
import EnvelopeBreakout from "../../Components/EnvelopeBreakout/EnvelopeBreakout.jsx";
import { deepClone } from "../../Components/lib/utils.jsx";

const TransReconciliation = ({
  i_show = false,
  i_BankTransaction,
  i_DBTransaction,
  i_DBTransEnvelopes,
  i_Envelopes,
  fi_handleSave,
  fi_handleCancel,
}) => {
  const [selectedValues, setSelectedValues] = useState({
    Payee: i_BankTransaction.Name,
    Amount: i_DBTransaction.amount,
    Date: i_BankTransaction.DatePosted,
    Note: i_BankTransaction.Memo,
    EnvelopeBreakdown: deepClone(i_DBTransEnvelopes),
    TransactionID: i_DBTransaction.transactionID,
  });

  // Show or Hide the popup based on the i_show parameter. This line has to be after all useState calls.
  if (!i_show) return null;

  const handleOptionSelect = (field, value) => {
    setSelectedValues((prevSelectedValues) => {
      return {
        ...prevSelectedValues,
        [field]: value,
      };
    });
  };

  const handleSave = () => {
    const transToSave = {
      TransactionID: selectedValues.TransactionID,
      Payee: selectedValues.Payee,
      Amount: selectedValues.Amount,
      Date: selectedValues.Date,
      Note: selectedValues.Note,
      CheckNumber: selectedValues.CheckNumber,
      FITID: i_BankTransaction.FITID,
      OrigTransID: i_DBTransaction.transactionID,
      EnvelopeBreakdown: selectedValues.EnvelopeBreakdown,
    };
    fi_handleSave(transToSave);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={true}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className="trans-reconciliation-modal"
      overlayClassName="trans-reconciliation-overlay"
    >
      <div className="trans-reconciliation-container">
        <h2>Reconcile Transaction</h2>

        {/* ** Date */}
        <div>
          <h5>Date</h5>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectDate'
              value={i_BankTransaction.DatePosted}
              onChange={() =>
                handleOptionSelect("Date", i_BankTransaction.DatePosted)
              }
              checked={selectedValues.Date == i_BankTransaction.DatePosted}
            />
            {i_BankTransaction.DatePosted + " (from bank)"}
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectDate'
              value={i_DBTransaction.date}
              onChange={() => handleOptionSelect("Date", i_DBTransaction.date)}
            />
            {new Date(i_DBTransaction.date).toLocaleDateString() +
              " (existing transaction)"}
          </label>
        </div>

        {/* ** Payee */}
        <div>
          <h5>Payee</h5>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectPayee'
              value={i_BankTransaction.Name}
              onChange={() =>
                handleOptionSelect("Payee", i_BankTransaction.Name)
              }
              checked={selectedValues.Payee == i_BankTransaction.Name}
            />
            {i_BankTransaction.Name + " (from bank)"}
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectPayee'
              value={i_DBTransaction.payee}
              onChange={() =>
                handleOptionSelect("Payee", i_DBTransaction.payee)
              }
            />
            {i_DBTransaction.payee + " (existing transaction)"}
          </label>
        </div>

        {/* ** Amount */}
        <div>
          <h5>Amount</h5>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectAmount'
              value={i_BankTransaction.Amount}
              onChange={() =>
                handleOptionSelect("Amount", i_BankTransaction.Amount)
              }
            />
            {currency(i_BankTransaction.Amount).divide(100).format() +
              " (from bank)"}
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectAmount'
              value={i_DBTransaction.amount}
              onChange={() =>
                handleOptionSelect("Amount", i_DBTransaction.amount)
              }
              checked={selectedValues.Amount == i_DBTransaction.amount}
            />
            {currency(i_DBTransaction.amount).divide(100).format() +
              " (existing transaction)"}
          </label>
        </div>

        {/* Envelopes */}
        <EnvelopeBreakout
          i_Envelopes={i_Envelopes}
          i_TotalAmount={selectedValues.Amount}
          i_InitialBreakout={i_DBTransEnvelopes}
          fi_HandleChange={(updatedEnvelopes) => {
            handleOptionSelect("EnvelopeBreakdown", updatedEnvelopes);
          }}
        />

        {/* ** Note */}
        <div>
          <h5>Note</h5>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectNote'
              value={i_BankTransaction.Memo}
              onChange={() =>
                handleOptionSelect("Note", i_BankTransaction.Memo)
              }
              checked={selectedValues.Note == i_BankTransaction.Memo}
            />
            {i_BankTransaction.Memo + " (from bank)"}
          </label>
        </div>
        <div>
          <label>
            <input
              type='radio'
              className='radio-input'
              name='selectNote'
              value={i_DBTransaction.note}
              onChange={() => handleOptionSelect("Note", i_DBTransaction.note)}
            />
            {i_DBTransaction.note == null
              ? "[blank]" + " (existing transaction)"
              : i_DBTransaction.note + " (existing transaction) "}
          </label>
        </div>
        <div className='button-group'>
          <button className='btn btn-primary' onClick={handleSave}>
            Save
          </button>
          <button
            className='btn btn-cancel'
            onClick={fi_handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TransReconciliation;
