import React from "react";
import "./FreeImports_Modal.css";
import Modal from "react-modal";
import ImportData from "../../Images/ImportData.png";

const FreeImports_Modal = ({ i_show, fi_handleCancel }) => {
  if (!i_show) return null;
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='free-imports-modal'
      overlayClassName='free-imports-overlay'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Effortless Data Migration</h2>
          <button onClick={fi_handleCancel} className='close-button'>
            &times;
          </button>
        </div>
        <div className='modal-body'>
          <div className='image-container'>
            <img
              src={ImportData}
              alt='Import Data'
              className='responsive-image'
            />
          </div>
          <div className='text-content'>
            <p>
              Say goodbye to the tedious process of setting up your budget from
              scratch. Easy Breezy Budget offers a seamless transition with our
              expert data import service.
            </p>
            <p>
              Whether you're migrating from another budgeting app, spreadsheets,
              or even paper records, our dedicated team ensures a smooth
              transition to Easy Breezy Budget.
            </p>
            <div className='why-choose-section'>
              <h3>Why Choose Our Import Service?</h3>
              <ul>
                <li>Save time and avoid manual data entry</li>
                <li>Ensure accuracy with expert handling</li>
                <li>Preserve your financial history</li>
                <li>Get a head start on effective budgeting</li>
              </ul>
            </div>
            <p className='highlight-text'>
              Experience the Easy Breezy Budget difference - where your
              financial journey begins with convenience and precision.
            </p>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={fi_handleCancel}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FreeImports_Modal;
