import "./Envelope.css";
import { amountPercentage, timeFramePercentage } from "../lib/utils";
import currency from "currency.js";

const Envelope = ({ i_envelope, fi_selectEnvelope }) => {
  // Setup Full Amount.
  let fullAmount = "";
  if (i_envelope.fullAmount !== null && i_envelope.fullAmount > 0) {
    fullAmount = currency(i_envelope.fullAmount).divide(100).format();
  }

  // Setup Time Frame Progress (how much time left in the time frame)
  let timeProgress = 0;
  if (i_envelope.showProgress) {
    timeProgress = timeFramePercentage(
      i_envelope.timeFrame,
      i_envelope.dueDate,
      i_envelope.type
    );
  }

  // Setup Amount Progress. How much money is left in the envelope vs. the full envelope amount. This is a percentage of the full envelope amount.
  let amountProgress = 0;
  if (i_envelope.showProgress) {
    amountProgress = amountPercentage(
      i_envelope.balance,
      i_envelope.fullAmount
    );
    if (amountProgress <= 0) {
      amountProgress = 0;
    }
  }

  return (
    <div
      className={
        i_envelope.type === 2 ? "card l-bg-green-dark" : "card l-bg-blue-dark"
      }
      onClick={() => {
        fi_selectEnvelope(i_envelope.id);
      }}>
      <div className='card-statistic-3 p-2'>
        <h5 className='card-title mb-2'>{i_envelope.envelopeName}</h5>
        <div className='row  align-items-end'>
          <div className='col-8'>
            <h2
              className={
                i_envelope.balance >= 0 ? "mb-0" : "mb-0 card-negative"
              }>
              {currency(i_envelope.balance).divide(100).format()}
            </h2>
          </div>
          <div className='col-4 text-end'>
            <span
              className={i_envelope.type === 2 ? "text-light" : "text-light"}>
              {fullAmount}
            </span>
          </div>
        </div>
        {i_envelope.showProgress === 1 && (
          <div
            className='progress mt-1 '
            data-height='8'
            style={{ height: "8px" }}>
            <div className='bar-step' style={{ left: timeProgress + "%" }}>
              {/* <div className='label-percent'>55%</div> */}
              {i_envelope.showProgressTick === 1 && (
                <div className='label-line'></div>
              )}
            </div>
            <div
              className={
                amountProgress > timeProgress
                  ? "progress-bar l-bg-green"
                  : "progress-bar l-bg-orange"
              }
              role='progressbar'
              data-width='25%'
              aria-valuenow='25'
              aria-valuemin='0'
              aria-valuemax='100'
              style={{ width: amountProgress + "%" }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Envelope;
