import Modal from "react-modal";
import { useState } from "react";
import "./Waitlist_Modal.css";

const Waitlist_Modal = ({ i_show, fi_handleCancel }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorText, setErrorText] = useState([]);

  const Waitlist_API = async () => {
    let strURL = `${process.env.REACT_APP_API_URL}/api/waitlist`;

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("email_confirmation", emailConfirmed);

    const response = await fetch(strURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log(response.status + " " + data.message);
      setSubmitting(false);
      setSuccess(true);
      setErrorOccured(false);
    } else {
      const data = await response.json();
      console.log("Failed API Call. Status: " + data.message);
      setSubmitting(false);
      setSuccess(false);
      setErrorOccured(true);

      // Improved error handling
      const errors = Object.values(data.errors).flat();
      setErrorText(errors);

      console.log(
        "Failed API Call. StatusText: " + Object.values(response.statusText)
      );
      console.log("Failed API call. Message: " + data.message);
      console.table(data.errors);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    Waitlist_API();
  };

  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='modal-custom waitlist-modal'
      overlayClassName='overlay-custom'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2 className='modal-title'>Beta Test / Early Access Waitlist</h2>
          <button
            type='button'
            className='close-button'
            onClick={fi_handleCancel}>
            ×
          </button>
        </div>
        <div className='modal-body'>
          <p className='modal-description'>
            Join our early access waitlist and be among the first to experience
            Easy Breezy Budget. Your feedback and support are invaluable as we
            fine-tune the details and prepare for the official launch.
          </p>
          {submitting && (
            <div className='alert alert-info' role='alert'>
              Saving Email Address...
            </div>
          )}
          {success && (
            <div className='alert alert-success' role='alert'>
              Email saved! Thanks for joining our waitlist.
            </div>
          )}
          {errorOccured && (
            <div className='alert alert-danger' role='alert'>
              {errorText.map((error, index) => (
                <div key={index}>{error}</div>
              ))}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <input
                type='text'
                id='name'
                className='form-control'
                placeholder='Your name'
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
              />
            </div>
            <div className='form-group'>
              <input
                type='email'
                id='email'
                className='form-control'
                placeholder='Email address'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='form-group'>
              <input
                type='email'
                id='emailConfirmation'
                className='form-control'
                placeholder='Confirm email address'
                required
                value={emailConfirmed}
                onChange={(e) => setEmailConfirmed(e.target.value)}
              />
            </div>
            <div className='form-actions'>
              <button type='submit' className='btn btn-primary'>
                Join Waitlist
              </button>
              <button
                type='button'
                className='btn btn-secondary'
                onClick={fi_handleCancel}>
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default Waitlist_Modal;
