import Envelope from "./../Envelope/Envelope";
import currency from "currency.js";

const Envelopes = ({ envelopes, selectEnvelope }) => {
  // Calculate the total of the transactions.
  let total = 0;
  let currentAmount = currency(0);
  for (let x of envelopes) {
    currentAmount = currency(x.balance).divide(100);
    total = currency(total).add(currentAmount);
  }
  return (
    <>
      <p>Balance = {currency(total).format()}</p>
      {envelopes.length <= 1 && (
        <p>
          <strong>
            *** Step 1: Click on the 'Edit Categories' button above to create
            your categories.
          </strong>
        </p>
      )}
      {envelopes.length > 1 &&
        envelopes.map((envelope) => (
          <Envelope
            key={envelope.id}
            i_envelope={envelope}
            fi_selectEnvelope={selectEnvelope}
          />
        ))}
    </>
  );
};

export default Envelopes;
