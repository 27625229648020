import currency from "currency.js";
import Transaction from "./../Transaction/Transaction";

const Transactions = ({ i_transactions, openEdit }) => {
  return (
    <>
      {!i_transactions.length && (
        <p>
          <strong>
            *** Step 2: Click on the 'Fill Categories' button above, then fill
            your categories using the current balance of your bank account.
          </strong>
        </p>
      )}
      {i_transactions.map((transaction) => (
        <Transaction
          key={transaction.transactionID}
          i_transaction={transaction}
          openEdit={openEdit}
        />
      ))}
    </>
  );
};

export default Transactions;
