import { BsCheck2, BsCheck2All } from "react-icons/bs";
import "./Transaction.css";
import currency from "currency.js";

const IsNull = (value) => value === null || value === undefined;

const Transaction = ({ i_transaction, openEdit }) => {
  let theRunningBalance = "";
  if (IsNull(i_transaction.runningBalance) != true) {
    theRunningBalance = currency(i_transaction.runningBalance)
      .divide(100)
      .format();
  }

  let theDate = new Date(i_transaction.date).toLocaleDateString("en-US", {
    timeZone: "UTC",
  });

  let color = "black";
  if (i_transaction.amount > 0) color = "green";

  return (
    <div
      className='transaction-container'
      onClick={() => openEdit(i_transaction.transactionID)}
    >
      <div className='transaction-main'>
        <div className='transaction-date'>{theDate}</div>
        <div className='transaction-payee-wrapper'>
          <span className='transaction-status'>
            {i_transaction.status == 1 ? (
              <BsCheck2All />
            ) : i_transaction.status == 2 ? (
              <BsCheck2 />
            ) : null}
          </span>
          <div className='transaction-payee'>{i_transaction.payee}</div>
        </div>
        <div className='transaction-amount' style={{ color: color }}>
          {currency(i_transaction.amount).divide(100).format()}
        </div>
      </div>
      <div className='transaction-details'>
        <div className='transaction-balance'>{theRunningBalance}</div>
      </div>
    </div>
  );
};

export default Transaction;
