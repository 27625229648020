import currency from "currency.js";
import { useDroppable } from "@dnd-kit/core";
import "./BankImport.css";

const DBTransDroppable = ({ i_dbTran, fi_handleUnmatchClick }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: "DBTran:" + i_dbTran.transactionID,
  });
  const rowClassName = isOver
    ? "overMatch"
    : i_dbTran.FITID !== null
    ? "match"
    : "";

  return (
    <tr key={i_dbTran.transactionID} ref={setNodeRef} className={rowClassName}>
      <th>{new Date(i_dbTran.date).toLocaleDateString()}</th>
      <th>{i_dbTran.payee}</th>
      {i_dbTran.newFITID !== null ? (
        <th>
          <button onClick={() => fi_handleUnmatchClick(i_dbTran.newFITID)}>
            Unmatch
          </button>
        </th>
      ) : (
        <th></th>
      )}
      <th>{currency(i_dbTran.amount).divide(100).format()}</th>
    </tr>
  );
};

export default DBTransDroppable;
